import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchPayments(context, payload) {
      return axios.get('payment', payload)
    },
    async deletePayment(context, payload) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        text: `Удалить платеж "${payload.payment_id}"?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios.delete(`payment/${payload.id}`)
        },
      }).then(result => {
        if (result.isConfirmed) {
          const { data } = result.value
          if (data) {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$swal({
              icon: 'success',
              text: 'Проект удален',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        }

        return result
      })
    },
    async unconfirmPayment(context, payload) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        text: `Снять подтверждение с платежа "${payload.payment_id}"?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios.put(`payment/unconfirm/${payload.id}`)
        },
      }).then(result => {
        if (result.isConfirmed) {
          const { data } = result.value
          if (data) {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$swal({
              icon: 'success',
              text: `Подтверждение с платежа "${payload.payment_id}" снято`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        }

        return result
      })
    },
    async confirmPayment(context, payload) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        text: `Подтвердить платеж "${payload.payment_id}"?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios.put(`payment/confirm/${payload.id}`)
        },
      }).then(result => {
        if (result.isConfirmed) {
          const { data } = result.value
          if (data) {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$swal({
              icon: 'success',
              text: `Платеж "${payload.payment_id}" подтвержден`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        }

        return result
      })
    },
  },
}
