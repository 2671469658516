import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

export default function usePaymentsList() {
  const refListTable = ref(null)
  const isAddNewPaymentSidebarActive = ref(false)
  const loading = ref(false)

  // Table Handlers
  /* eslint-disable */
  const tableColumns = [
    { key: 'createdAt', sortable: true, label: 'Дата', thClass: 'text-left', tdClass: 'text-left' },
    { key: 'payment_id', sortable: true, label: 'ID платежа', thClass: 'text-left', tdClass: 'text-left' },
    { key: 'user', sortable: true, label: 'Клиент', thClass: 'text-left' },
    { key: 'amount', sortable: true, label: 'Сумма', thClass: 'text-left', tdClass: 'text-left' },
    { key: 'status', sortable: true, label: 'Статус', thClass: 'text-left', tdClass: 'text-left' },
    { key: 'actions', label: '', sortable: false },
  ]

  const perPage = ref(25)
  const total = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref('10')

  const blankPaymentData = {
    name: '',
    user_id: '',
    kpi_type: 'Цена лида',
    kpi_sum: 0,
  }

  const payment = ref(JSON.parse(JSON.stringify(blankPaymentData)))

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: total.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchPayments = (ctx, callback) => {
    loading.value = true
    store
      .dispatch('app-payments/fetchPayments', {
        params: {
          // filter: JSON.stringify({
          //   status: statusFilter.value,
          // }),
          perPage: perPage.value,
          page: currentPage.value,
        },
      })
      .then(response => {
        const { data, count } = response.data

        callback(data)
        total.value = count
        loading.value = false
      })
      .catch(() => {
        loading.value = false
      })
  }

  const deletePayment = payment => {
    store.dispatch('app-payments/deletePayment', payment).then(() => {
      refetchData()
    })
  }
  const confirmPayment = payment => {
    store.dispatch('app-payments/confirmPayment', payment).then(() => {
      refetchData()
    })
  }
  const unconfirmPayment = payment => {
    store.dispatch('app-payments/unconfirmPayment', payment).then(() => {
      refetchData()
    })
  }

  const resolveStatusVariant = status => {
    if (status === 'NEW') return 'warning'
    if (status === 'CONFIRMED') return 'success'
    return 'warning'
  }

  const resolveStatusText = status => {
    if (status === 'CONFIRMED') return 'Подтвержден'
    if (status === 'NEW') return 'Новый'
    return 'Новый'
  }

  return {
    isAddNewPaymentSidebarActive,
    loading,
    payment,
    fetchPayments,
    confirmPayment,
    unconfirmPayment,
    deletePayment,
    tableColumns,
    perPage,
    currentPage,
    total,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    refetchData,

    resolveStatusVariant,
    resolveStatusText,
  }
}
